* {
	box-sizing: border-box;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

body {
	height: 100vh;
	padding: 0;
	justify-content: center;
	align-items: center;
	display: flex;
	/*background-image: url(background.png);*/
	/*background-size: cover;*/
	background: #141414;
	color: #eee;
	font-family: Arial;
}

#root {
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}

div.tracker {
	z-index: 999;
	/*position: fixed;*/
	left: 0;
	top: 0;
}

div.tracker.fixed {
	position: fixed;
}

div.tracker {
	/*box-shadow: 0 1px 5px rgba(0, 0, 0, .8);*/
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

div.tracker .tracker-notification {
	z-index: 9999;
	background: rgba(20, 20, 20, 1);
	position: absolute;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 20s;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

div.tracker .tracker-notification .message {
	display: inline-block;
	text-align: center;
	color: #ccc;
}

div.tracker .tracker-notification .message .highlight {
	color: #FFCC33;
}

div.tracker .tracker-notification .message .highlight.item-found {
	 display: block;
}

div.tracker.small .message {
	font-size: 11px;
	padding: 5px;
	line-height: 13px;
}

div.tracker.small .message .highlight.item-found {
	 font-size: 110%;
	 padding: 5px 0;
}

div.tracker.small table {
	width: 170px;
}

div.tracker.small table th {
	font-size: 10px;
}

div.tracker.small table td.bigly {
	font-size: 150%;
}

div.tracker.small table td.midly {
	font-size: 100%;
}

div.tracker.small table tr.padless th {
	padding-top: 2px !important;
	padding-bottom: 0 !important;
}

div.tracker.small table tr.padless td {
	padding-bottom: 0 !important;
}

div.tracker.medium .message {
	font-size: 11px;
	padding: 5px;
	line-height: 15px;
}

div.tracker.medium .message .highlight.item-found {
	 font-size: 125%;
	 padding: 8px 0;
}

div.tracker.medium table {
	width: 170px;
}

div.tracker.medium table th {
	font-size: 10px;
}

div.tracker.medium table td.bigly {
	font-size: 150%;
}

div.tracker.medium table td.midly {
	font-size: 100%;
}

div.tracker.medium table tr.padless th {
	padding-top: 2px !important;
	padding-bottom: 0 !important;
}

div.tracker.medium table tr.padless td {
	padding-bottom: 0 !important;
}

div.tracker.medium table tr.padless:last-of-type td {
	padding-bottom: 5px !important;
}

div.tracker.medium span.skull {
	width: 16px;
	height: 16px;
}

div.tracker.big .message {
	font-size: 12px;
	padding: 10px 15px;
	line-height: 15px;
}

div.tracker.big .message .highlight.item-found {
	 font-size: 130%;
	 padding: 10px 0;
}

div.tracker table {
	/*border: 1px solid #333333;*/
	color: #ccc;
	font-size: 12px;
	margin: auto;
	/*background: rgba(20, 20, 20, 1);*/
	width: 350px;
}

div.tracker table tr.padless td,
div.tracker table tr.padless th {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

div.tracker table tr.padless th {
	padding-top: 10px !important;
}

div.tracker table tr.padless td {
	padding-bottom: 10px !important;
}

div.tracker table tr td.bigly {
	font-size: 200%;
}

div.tracker table tr td.midly {
	font-size: 125%;
}

div.tracker table tr td.yellow {
	color: #FFCC33;
}

div.tracker table tr.blur td {
	color: #888;
}

div.tracker table tr.ahead span.exp {
	color: #31b766 !important;
}

div.tracker table tr.ahead span.exp::before {
	content: "+";
}

div.tracker table tr.behind span.exp {
	color: #c25d58 !important;
}

.ahead-points {
	color: #31b766 !important;
}

.ahead-points::before {
	content: "+";
}

.behind-points {
	color: #c25d58 !important;
}

.same-points {
	color: #888 !important;
}

div.tracker table tr th {
	padding: 2px 10px;
}

div.tracker table tr td {
	padding: 2px 10px;
}

div.tracker span.skull {
	position: relative;
	display: inline-block;
	background: url(dead.png);
	background-size: cover;
	width: 24px;
	height: 24px;
}

span.rank {
	display: inline-block;
	width: 40px;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
}

span.name {
	display: inline-block;
	width: 180px;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
}

span.name.dead {
	text-decoration: line-through;
}

.digit {
	font-family: 'Inconsolata', monospace;
}

span.exp {
	text-align: right;
	float: right;
	display: inline-block;
	width: 90px;
	text-overflow: ellipsis;
	overflow-x: hidden;
	white-space: nowrap;
}

div.character-not-found {
	padding: 10px 0;
	text-align: center;
	display: block;
}

.arrow-container {
	position: relative;
	display: inline-block;
	text-align: center;
}

.arrow-container span {
	float: left;
	margin-right: 5px;
}

.arrow-container span:not(.arrow) {
	margin-right: 0;
}

.arrow-container .arrow.red {
	margin-top: 5px;
	margin-right: 2px;

	display: inline-block;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-top: 5px solid #ff4b52;
 }

.arrow-container .arrow.green {
	margin-top: 3px;
	margin-right: 2px;

	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-bottom: 5px solid rgba(80, 191, 80, 1);
}

.chunky {
	font-weight: bold;
}

.tracker-actions {
	text-align: center;
	padding: 10px 0;
}

.tracker-actions div.tracker-action {
	cursor: pointer;
	margin: 0 10px;
	border-radius: 5px;
	height: 30px;
	width: 30px;
	line-height: 29px;
	text-align: center;
	display: inline-block;
}

.tracker-actions div.tracker-action i.fa {
	line-height: 29px;
	font-size: 140%;
	color: #bbb;
}

.tracker-actions div.tracker-action.red {
	background: rgba(168, 24, 23, 0.8);
}

.tracker-actions div.tracker-action.blue {
	background: rgba(29, 78, 173, 0.8);
}

.tracker-actions div.tracker-action.green {
	background: rgba(28, 108, 60, 0.8);
}

.tracker-actions div.tracker-action:hover {
	opacity: 1;
	color: #ddd;
}

.tracker-actions div.tracker-action:hover.red {
	background: rgba(168, 24, 23, 1);
 }

.tracker-actions div.tracker-action:hover.blue {
	background: rgba(29, 78, 173, 1);
 }

.tracker-actions div.tracker-action:hover.green {
	background: rgba(28, 108, 60, 1);
 }

.center {
	text-align: center;
}

.xp-per-hour {
	position: relative;
	color: lightgreen !important;
}

/*

@graph-color: #2d9443 !important;
@graph-hover-color: #9af783 !important;

@secondary-graph-color: #4c91d9 !important;
@secondary-graph-hover-color: #7bb5f5 !important;
 */

.xpg-tooltip {
	padding: 8px 10px;
	background: black;
	z-index: 999;
	display: inline-block;
	white-space: nowrap;
	top: -75px;
	width: 140px;
	text-align: center;
	line-height: 16px;
	pointer-events: none;
}

.xpg-tooltip div:first-of-type {
	font-weight: normal;
	font-style: italic;
	font-size: 11px;
}

.xpg-tooltip div:nth-of-type(2) {
	margin: 3px 0 2px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.xpg-tooltip div:nth-of-type(2) span span:first-of-type {
	margin-right: 4px;
	font-weight: normal;
	opacity: .8;
	letter-spacing: .1px;
	font-size: 90%;
}

.xpg-tooltip div:nth-of-type(2) span span:nth-of-type(2) {
	color: #81da81 !important;
}

.xpg-tooltip div:nth-of-type(3) {
	font-size: 11px;
}